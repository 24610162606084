import React, { useContext } from "react";
import SEO from "../../components/seo";
import CustomAuth from "../../components/custom-auth";
import { UserContext } from "../../provider/usercontextprovider";
import CreateMedicalTest from "../../components/create-medical-test";
import { UserTenantProvider } from "../../provider/usertenantprovider";

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isEnroller =
    userContext.username &&
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("TenantEnroller");

  const isAdmin =
    userContext.username &&
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("TenantAdmin");

  return(
    <CustomAuth>
      <SEO title="Create Medical Test" />
      <UserTenantProvider>
        {(isEnroller || isAdmin)  ? <CreateMedicalTest userContext={userContext} /> : <p>User is not an enroller</p> }
      </UserTenantProvider>
    </CustomAuth>
  );
}

export default ProtectedPage;