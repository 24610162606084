import React, { useContext, useEffect, useState } from "react";
import * as enrollerStyles from "./enroller.module.css";
import * as buttonStyles from "./button.module.css";
import Select from "react-select";
import { API, graphqlOperation } from "aws-amplify";
import { LoadMaskContext } from "../provider/loadmaskprovider";
import { Notification, NotificationType } from "./notification";
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { UserTenantContext } from "../provider/usertenantprovider";
import { validateEmail, validateNumber, validatePhone } from "./utils";

const listEnrollerConfigurations = /* GraphQL */ `
query listEnrollerConfigurations {
    listEnrollerConfigurations {
        items {
            name
            prettyName
            enrollerGroup
        }
    }
}`

const createMedicalTest = /* GraphQL */ `mutation createMedicalTest($input: CreateMedicalTestInput!){
  createMedicalTest(input: $input){
    enrolleeIdentifier
  }
}`


const CreateMedicalTest = ({ userContext }) => {
  const [isLoading, setIsLoading] = useContext(LoadMaskContext);
  const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
  const [state, setState] = useState({
    tenantId: null,
    enrollerGroup: null,
    enrolleeIdentifier: null,
    enrolleeFirstName: null,
    enrolleeLastName: null,
    enrolleeEmailAddress: null,
    enrolleeDateOfBirth: null,
    enrolleeRace: null,
    enrolleeEthnicity: null,
    enrolleeGender: null,
    enrolleeZipCode: null,
    enrolleeCounty: null,
    enrolleeAddress: null,
    enrolleePhone: null,
    enrolleeCity: null,
    enrolleeState: null,
    orderingProviderNPI: null,
    orderingProviderName: null,
    orderingProviderZipCode: null,
    orderingProviderPhone: null,
    orderingProviderAddress: null
  });
  const [message, setMessage] = useState({
    show: false,
  });

  const fields = [
    { id: "enrolleeIdentifier", label: "Enrollee ID" },
    { id: "enrolleeFirstName", label: "Enrollee First Name" },
    { id: "enrolleeLastName", label: "Enrollee Last Name" },
    { id: "enrolleeEmailAddress", label: "Enrollee Email Address", type: "email" },
    { id: "enrolleeDateOfBirth", label: "Enrollee DOB", type: "date" },
    { id: "enrolleeRace", label: "Enrollee Race", type: "choice", values: [{ value: "American Indian or Alaska Native", label: "American Indian or Alaska Native" }, { value: "Asian", label: "Asian" }, { value: "Black or African American", label: "Black or African American" }, { value: "White", label: "White" }, { value: "Native Hawaiian or Other Pacific Islander", label: "Native Hawaiian or Other Pacific Islander" }, { value: "Other", label: "Other" }, { value: "Unknown", label: "Unknown" }, { value: "Declined", label: "Declined" }] },
    { id: "enrolleeEthnicity", label: "Enrollee Ethnicity", type: "choice", values: [{ value: "Unknown", label: "Unknown"}, { value: "Other", label: "Other"}, { value: "Not Hispanic or Latino", label: "Not Hispanic or Latino"}, { value: "Declined", label: "Declined"}] },
    { id: "enrolleeGender", label: "Enrollee Sex", type: "choice", values: [{value:"Male", label: "Male"}, {value:"Female", label: "Female"}, {value:"Other", label: "Other"}] },
    { id: "enrolleeZipCode", label: "Enrollee Residence Zip" },
    { id: "enrolleeCounty", label: "Enrollee County" },
    { id: "enrolleeAddress", label: "Enrollee Street Address" },
    { id: "enrolleePhone", label: "Enrollee Phone Number", type: "phone" },
    { id: "enrolleeCity", label: "Enrollee City" },
    { id: "enrolleeState", label: "Enrollee State" },
    { id: "orderingProviderNPI", label: "Ordering Provider NPI" },
    { id: "orderingProviderName", label: "Ordering Provider Name" },
    { id: "orderingProviderZipCode", label: "Ordering Provider Zip" },
    { id: "orderingProviderPhone", label: "Ordering Provider Phone", type: "phone" },
    { id: "orderingProviderAddress", label: "Ordering Provider Address" }
  ];

  const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName,
    }
  });

  const createTest = async () => {
    setMessage({ show: false });
    let validation = {
      enrolleeEmailAddress: validateEmail(state.enrolleeEmailAddress),
      enrolleePhone: validatePhone(state.enrolleePhone),
      enrollerZipCode: validateNumber(state.enrolleeZipCode),
      orderingProviderPhone: validatePhone(state.orderingProviderPhone),
      orderingProviderZipCode: validateNumber(state.orderingProviderZipCode),
    };
    if (validation.enrollerZipCode == false || validation.orderingProviderZipCode == false) {
      setMessage({
        show: true,
        msg: "Please make sure Enrollee Residence Zip and Ordering Provider Zip are numbers.",
        messageType: NotificationType.FAIL,
      });
    } else if (validation.enrolleePhone == false || validation.orderingProviderPhone == false) {
      setMessage({
        show: true,
        msg: "Please make sure Enrollee Phone Number and Ordering Provider Phone are phone numbers.",
        messageType: NotificationType.FAIL,
      });
    } else if (validation.enrolleeEmailAddress == false) {
      setMessage({
        show: true,
        msg: "Please make sure Enrollee Email Address is a correct email address.",
        messageType: NotificationType.FAIL,
      });
    } else {
      setIsLoading(true);
      try {
        await API.graphql(
          graphqlOperation(createMedicalTest, {input: state})
        );
        setMessage({
          show: true,
          msg: 'Successfully created medical record',
          messageType: NotificationType.SUCCESS,
        });
      } catch (error) {
        setMessage({
          show: true,
          msg: 'Failed to create medical record',
          messageType: NotificationType.FAIL,
        });
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <div>
      <h2>Add a new medical test</h2>

      {message.show &&
        <Notification messageType={message.messageType}>
          <p>{message.msg}</p>
        </Notification>
      }

      <div className={enrollerStyles.form}>
        <div className={enrollerStyles.inputWrapper}>
          <label className={enrollerStyles.label}>
            Tenant
          </label>
          <Select
            options={options}
            onChange={e => setState({ ...state, tenantId: e.value, enrollerGroup: `Enroller${e.label.replace(/\s+/g, '')}` })}
          />
        </div>

        {
          fields.map(field => 
            <div className={enrollerStyles.inputWrapper} key={`${field.id}-key`}>
              <label
                className={enrollerStyles.id}
                htmlFor={field.label}
              >
                {field.label}
                  </label>
                  {field.type == 'date' && <><DayPicker className={enrollerStyles.input} inputProps={{ disabled: !state.tenantId }} onDayChange={e => {
                      if (e !== undefined) {
                        let s = {...state}
                        s[field.id] = e.toISOString()
                        setState(s)
                      }
                    }}/>
                    <style>
                        {`
                        .DayPicker {
                            width: 100%;
                        }
                        .DayPicker input {
                            background-color: rgb(255, 255, 255);
                            min-height: 38px;
                            box-sizing: border-box;
                            border-color: rgb(204, 204, 204);
                            border-radius: 4px;
                            border-style: solid;
                            border-width: 1px;
                            padding: 0.5rem;
                            width: 100%;
                        }
                    `}
                    </style></>}
                    {field.type == 'choice' && <>
                      <Select options={field.values} isDisabled={!state.tenantId} onChange={e => {
                        let s = {...state}
                        s[field.id] = e.value
                        setState(s)
                      }} />
                    </>}
                    {field.type == 'phone' && <><input
                      className={enrollerStyles.input}
                      type="tel"
                      name={field.label}
                      disabled={!state.tenantId}
                      onChange={e => {
                        let s = {...state}
                        s[field.id] = e.currentTarget.value
                        setState(s)
                      }}
                    /></>}
                    {field.type == 'email' && <><input
                      className={enrollerStyles.input}
                      type="email"
                      name={field.label}
                      disabled={!state.tenantId}
                      onChange={e => {
                        let s = {...state}
                        s[field.id] = e.currentTarget.value
                        setState(s)
                      }}
                    /></>}
                    {!field.type && <><input
                      className={enrollerStyles.input}
                      type="text"
                      name={field.label}
                      disabled={!state.tenantId}
                      onChange={e => {
                          let s = {...state}
                          s[field.id] = e.currentTarget.value
                          setState(s)
                        }
                      }
                    /></>}
            </div>
          )
        }

        <div className={enrollerStyles.buttonWrapper}>
          <button
            type="button"
            className={buttonStyles.button}
            disabled={!state.tenantId || !state.enrolleeIdentifier || !state.orderingProviderAddress || !state.orderingProviderNPI || !state.orderingProviderName || !state.orderingProviderPhone || !state.orderingProviderZipCode}
            onClick={() => createTest()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateMedicalTest;